// 应用权限分配弹窗
// sle
<template>
  <a-spin :spinning="viewSpinning">
    <div
      class="showMain flex-column justify-between"
      :style="{ minHeight: minHeight + 'px' }"
    >
      <div class="head">
        <page-head title="权限管理" />
        <div class="transfer">
          <!-- <a-button disabled title="敬请期待" @click="enterpriseTransfer" -->
          <a-button
            v-if="creatorId == currentUserId"
            @click="enterpriseTransfer"
            >企业转移</a-button
          >
        </div>
        <div class="top flex-row">
          <div class="flex-leftContent top-item role">
            <text class="flex-leftContent topText">角色</text>
            <a v-if="roleEdit"
              ><img
                class="addButton"
                :onclick="addRole"
                src="@/assets/Icon_Addition.png "
            /></a>
            <a class="permission_edit" v-if="!roleEdit" :title="'暂无权限'"
              ><img
                class="addButton"
                style="width: 32px; height: 32px"
                src="@/assets/icon_add.png "
            /></a>
          </div>
          <div class="top-item flex-leftContent user">
            <text class="topText">用户</text>
          </div>
          <div class="top-item flex-leftContent permission">
            <text class="topText">权限</text>
          </div>
        </div>
        <div class="middle flex-row">
          <div class="top-item leftContents role">
            <a-spin :spinning="roleSpinning">
              <a-list
                item-layout="horizontal"
                class="roleList"
                :data-source="roleList"
                :split="false"
                v-model:selectedKeys="roleSelectKey"
              >
                <template #renderItem="{ item }">
                  <a class="flex-row align-center leftItemTitle">
                    <a-list-item
                      class="menuItem"
                      :key="item.id"
                      :style="
                        roleSelectItem.id == item.id ? ischoise : notChoise
                      "
                      @click="roleChange(item)"
                    >
                      {{ item.name }}
                    </a-list-item>
                    <a-dropdown :trigger="roleEdit ? ['click'] : ''">
                      <a
                        :class="roleEdit ? '' : 'permission_edit'"
                        v-if="item.name != 'admin'"
                        :style="
                          roleSelectItem.id == item.id ? ischoise : notChoise
                        "
                      >
                        <MoreOutlined :title="roleEdit ? '' : '暂无权限'" />
                        <!-- &nbsp;&nbsp;&nbsp;· · · -->
                      </a>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item key="del">
                            <a-popconfirm
                              cancelText="取消"
                              okText="确认"
                              :title="'是否确认删除角色 ' + item.name"
                              @confirm="submitDeleteRole(item.id)"
                            >
                              <a>删 除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </a>
                </template>
              </a-list>
            </a-spin>
          </div>
          <div class="top-item leftContents user">
            <a-spin :spinning="userSpinning">
              <a-checkbox-group
                class="menu flex-column jurisdictionView-checkbox-group"
                v-model:value="userSelectKey"
                :options="userList"
                :disabled="userSelectBanCheck"
              >
              </a-checkbox-group>
            </a-spin>
          </div>
          <div class="top-item leftContents permission">
            <a-spin :spinning="permissionSpinning">
              <a-checkbox-group
                class="menu flex-column jurisdictionView-checkbox-group"
                v-model:value="permissionSelectKey"
                :options="permissionList"
                :disabled="permissionBanCheck"
              >
              </a-checkbox-group>
            </a-spin>
            <div class="foot">
              <div class="bottom flex-rowReverse">
                <div class="flex-rowReverse saveButton">
                  <a-button
                    type="primary"
                    @click="saveConfirm"
                    :disabled="!permissionEdit"
                  >
                    保存
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-modal
          v-model:visible="addRoleVisible"
          :maskClosable="false"
          :closable="false"
          title="新增角色"
          ><template #footer>
            <a-button key="back" @click="addRoleCancel()">取消</a-button>
            <a-button key="submit" type="primary" @click="submitAddRole()"
              >确认</a-button
            >
          </template>
          <a-form ref="formRef" :rules="rules" :model="obj">
            <a-form-item name="name" required label="角色名称">
              <a-input v-model:value="obj.name" class="input" />
            </a-form-item>
          </a-form>
        </a-modal>
        <a-modal
          v-model:visible="transferVisible"
          :maskClosable="false"
          title="企业转移"
          ok-text="确认"
          cancel-text="取消"
          :confirmLoading="confirmLoading"
          @ok="okText"
          @cancel="cancelText"
        >
          <a-spin :spinning="spinning">
            <a-form :model="register" ref="formRef" :rules="rules">
              <a-form-item
                required
                name="selectEmployeeName"
                label="选择转让人员："
              >
                <a-select
                  style="width: 300px"
                  v-model:value="register.selectEmployeeName"
                  :allowClear="true"
                >
                  <a-select-option
                    v-for="r in enterpriseList"
                    :key="r.id"
                    :value="r.phoneNumber"
                    >{{ r.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="是否退出当前企业：">
                <a-radio-group :default-value="1" @change="onChange">
                  <a-radio :value="1"> 否 </a-radio>
                  <a-radio :value="2"> 是 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>
        <a-modal
          v-model:visible="visible"
          title="提示"
          ok-text="确认"
          :maskClosable="false"
          cancel-text="取消"
          footer
          :closable="false"
        >
          <a-spin :spinning="spinning">
            <div class="refresh">您退出当前企业,需重新登录</div>
            <div class="handle_logout">
              <a-button type="primary" @click="handleLogout"> 确定 </a-button>
            </div>
          </a-spin>
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { defineComponent } from 'vue'
import { isEmpty, permissionEditJurisdiction, roleEditJurisdiction } from '@/assets/common.js'
import api from '@/api/API'
import { getProfileDetail } from '@/api/IdentityAPI.js'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import { MoreOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    'page-head': PageHead,
    MoreOutlined,
  },
  data () {
    return {
      register: {
        selectEmployeeName: '', // 选择的转让人
      },
      visible: false,
      spinning: false,
      confirmLoading: false,
      isExit: false,
      value: 1, // 是否退出当前企业按钮
      transferVisible: false, // 企业转移弹框是否显示
      enterpriseList: [],
      permissionEdit: false, // 是否有权限编辑的权限
      roleEdit: null, // 是否有角色管理的权限
      minHeight: 0,
      maxHeight: 600,
      currentUserId: '', // 当前登录用户Id
      creatorId: null, // 当前企业创建人Id
      currentPermission: null, // 当前登录人权限
      moreDisabled: true, // 编辑按钮是否启用
      viewSpinning: false, // 页面是否加载
      roleList: [], // 角色列表
      roleSpinning: false, // 角色列表是否加载
      roleSelectKey: [], // 角色选中列表
      roleSelectItem: { id: '', name: '' }, // 角色选中项
      addRoleVisible: false, // 添加角色确认页面是否显示
      obj: {
        name: '', // 角色名称
      }, // 编辑的实体类

      userList: [], // 用户列表
      userSpinning: false, // 用户列表是否加载
      userSelectKey: [], // 用户选中项

      permissionList: [], // 权限列表
      permissionSpinning: false, // 权限列表是否加载
      permissionSelectKey: [], // 权限选中项
      userSelectBanCheck: true, // 是否禁止分配用户
      permissionBanCheck: true, // 是否禁止选择权限

      ischoise: 'color:#3399ff', // 选中时颜色
      notChoise: 'color:black', // 未选中时颜色
      rules: {
        selectEmployeeName: [
          {
            required: true,
            message: '请选择转让人员',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '角色名称不能为空',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      error: false,
    }
  },
  created () {
    this.minHeight = minheight
    this.roleSelectItem = { id: '', name: '' }
    this.permissionEdit = permissionEditJurisdiction()
    this.roleEdit = roleEditJurisdiction()
    getProfileDetail().then(data => {
      if (data.status === 1) {
        this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
        window.location.href = '/account/login'
        window.localStorage.clear()
      }
      this.currentPermission = data.permissions
      this.currentUserId = data.abpId
    })
    this.getCreator()
    this.getRoleList()
    this.getPermissionList()
    this.getUserList()
  },
  methods: {
    // 确定退出
    handleLogout () {
      this.spinning = true
      window.location.href = '/account/login'
    },
    // 确认企业转移
    okText () {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        this.spinning = true
        api
          .post('/api/app/ent-transfer/ent-transfer', {
            phoneNumber: this.register.selectEmployeeName,
            isExit: this.isExit,
          })
          .then(({ data }) => {
            this.transferVisible = false
            this.confirmLoading = false
            this.spinning = false
            this.register.selectEmployeeName = ''
            this.$message.success('企业转移成功')
            if (this.isExit === true) {
              this.visible = true
              window.localStorage.clear()
              // 清除cookie
              window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
              window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
              history.pushState(null, null, document.URL)
              window.addEventListener(
                'popstate',
                function (e) {
                  history.pushState(null, null, document.URL)
                },
                false,
              )
            } else {
              getProfileDetail().then(data => {
                if (data.status === 1) {
                  this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
                  window.location.href = '/account/login'
                  window.localStorage.clear()
                }
                localStorage.setItem('userInfo', JSON.stringify(data))
                this.$router.go(0)
                window.location.href = '/EnterpriseManagement/UseringDetail'
              })
            }
          })
          .catch(err => {
            this.transferVisible = false
            this.confirmLoading = false
            this.spinning = false
            this.register.selectEmployeeName = ''
            console.log(err)
          })
      })
    },
    cancelText () {
      this.spinning = false
      this.$refs.formRef.resetFields()
    },
    onChange (e) {
      if (e.target.value === 2) {
        this.isExit = true
      } else {
        this.isExit = false
      }
    },
    // 企业转移
    enterpriseTransfer () {
      this.transferVisible = true
      api
        .get('/api/app/ent-transfer/ent-user-list')
        .then(({ data }) => {
          this.enterpriseList = data
        })
        .catch(err => {
          // this.roleSpinning = false
          // this.$message.error(err.response.data.error.message)
          // this.$message.error('获取角色数据失败')
          console.log(err)
        })
    },
    // 获取创建者Id
    getCreator () {
      api
        .get('/api/app/ent-transfer/ent-is-creator')
        .then(({ data }) => {
          this.roleSpinning = false
          this.creatorId = data
          // console.log(this.creatorId, 'creator')
        }).catch(err => {
          this.roleSpinning = false
          // this.$message.error(err.response.data.error.message)
          // this.$message.error('获取角色数据失败')
          console.log(err)
        })
    },
    // 获取角色list
    getRoleList () {
      this.roleSpinning = true
      api
        .get('/api/identity/roles/all')
        .then(({ data }) => {
          this.roleSpinning = false
          this.roleList = data.items
        })
        .catch(err => {
          this.roleSpinning = false
          // this.$message.error(err.response.data.error.message)
          // this.$message.error('获取角色数据失败')
          console.log(err)
        })
    },
    // 获取用户list
    getUserList () {
      this.userSpinning = true
      api
        .get('/api/identity/users/GetUserAntCheckBoxList')
        .then(({ data }) => {
          this.userSpinning = false
          this.userList = data
        })
        .catch(err => {
          this.userSpinning = false
          // this.$message.error(err.response.data.error.message)
          // this.$message.error('获取用户数据失败')
          console.log(err)
        })
    },
    // 提交角色添加表单
    submitAddRole () {
      this.$refs.formRef.validate().then(() => {
        this.roleSpinning = true
        api
          .post('/api/identity/roles', this.obj)
          .then(({ data }) => {
            this.$message.success('保存成功')
            this.getRoleList()
            this.getPermissionList()
            this.getUserList()
            this.obj = {}
            this.roleSpinning = false
            this.error = false
          })
          .catch(err => {
            this.obj = {}
            this.roleSpinning = false
            this.error = false

            // this.$message.error(err.response.data.error.message)
            console.log(err)
          })
        this.addRoleVisible = false
      })
    },
    // 删除角色
    submitDeleteRole (roleId) {
      api
        .delete('/api/identity/roles/{' + roleId + '}')
        .then(({ data }) => {
          this.$message.success('删除成功')
          this.getRoleList()
          this.getPermissionList()
          this.getUserList()
        })
        .catch(err => {
          // this.$message.error(err.response.data.error.message)
          console.log(err)
        })
      this.addRoleVisible = false
    },
    // 获取权限list
    getPermissionList () {
      this.permissionSpinning = true
      api
        .get('/api/permission-management/permissions/GetPermissionAntCheckBoxListAsync')
        .then(({ data }) => {
          this.permissionSpinning = false
          this.permissionList = data
        })
        .catch(err => {
          this.permissionSpinning = false
          // this.$message.error(err.response.data.error.message)
          // this.$message.error('获取权限数据失败')
          console.log(err)
        })
    },

    // 权限改变项
    roleChange (e) {
      console.log(e)
      this.roleSelectItem = e
      this.getUserByRole()
      this.getPermissionByRole()
    },
    // 获取当前角色下的人
    getUserByRole () {
      this.userSpinning = true
      api
        .get('/api/identity/roles/GetIdentityByRoleIdAsync', {
          params: {
            roleId: this.roleSelectItem.id,
          },
        })
        .then(({ data }) => {
          this.userSpinning = false
          this.userSelectKey = data
        })
        .catch(err => {
          this.userSpinning = false
          // this.$message.error(err.response.data.error.message)
          console.log(err)
        })
    },
    // 获取当前角色下的权限
    getPermissionByRole () {
      this.permissionSpinning = true
      api
        .get('/api/permission-management/permissions/GetListByRoleName', {
          params: {
            providerKey: this.roleSelectItem.name,
          },
        })
        .then(({ data }) => {
          this.permissionSpinning = false
          this.permissionSelectKey = data.checkList
          this.permissionBanCheck = (data.check || (this.currentPermission.indexOf('Platform.PlatformPermission_Edit') === -1))
          this.userSelectBanCheck = (this.currentPermission.indexOf('Platform.PlatformPermission_Edit') === -1)
        })
        .catch(err => {
          this.permissionSpinning = false
          // this.$message.error('获取权限数据失败')
          console.log(err)
        })
    },

    addRole () {
      this.addRoleVisible = true
    },
    addRoleCancel () {
      this.$refs.formRef.resetFields()
      this.addRoleVisible = false
    },
    // 保存
    saveConfirm () {
      if (isEmpty(this.roleSelectItem)) {
        this.$message.error('请先选择角色')
        return
      }
      const obj = {
        roleId: this.roleSelectItem.id,
        roleName: this.roleSelectItem.name,
        userList: this.userSelectKey,
        creatorId: this.creatorId,
        permissionList: this.permissionSelectKey,
      }
      if (obj.roleName === 'admin' && obj.userList.length === 0) {
        this.$message.error('admin权限下至少需要指定一人')
        return
      }
      this.viewSpinning = true
      api
        .post('/api/identity/roles/RolePermissionAndUserConfig', obj)
        .then(({ data }) => {
          this.viewSpinning = false
          this.$message.success('保存成功')
        })
        .catch((err) => {
          this.viewSpinning = false
          // this.$message.error('保存失败')
          console.log(err)
        })
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";

.showMain {
  background: #fff;
  width: 100%;
  .transfer {
    position: absolute;
    top: 14px;
    right: 30px;
  }
  .role {
    width: 26%;
    max-height: 600px;
    text-align: left;
    padding: 0px 15px;
    overflow: auto;
    border-right: 1px solid #dddddd;
  }
  .roleList {
    width: 100%;
    margin-top: 5px;
    min-height: 400px;
    .leftitem {
      margin-left: 10%;
      width: 0px;
    }
    .menuItem {
      margin-left: 10%;
      width: 0px;
    }
    .leftItemTitle {
      width: 74%;
    }
    .ant-list-item {
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ant-dropdown-trigger {
      position: absolute;
      left: 175px;
    }
  }
  .topText {
    padding-left: 12px;
    margin-right: 50%;
    font-size: 16px;
  }
  .user {
    width: 37%;
    max-height: 600px;
    text-align: left;
    padding: 0px 15px;
    overflow: auto;
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
  }
  .permission {
    width: 37%;
    max-height: 600px;
    text-align: left;
    padding: 0px 15px;
    overflow: auto;
    border-left: 1px solid #dddddd;
  }
  .top {
    width: 100%;
    background: #ffffff;
    border-top: 1px solid #dddddd;
    height: 50px;
  }
  .middle {
    width: 100%;
  }
  .bottom {
    width: 100%;
  }
  .saveButton {
    padding: 50px 20px 20px;
  }
  .line-h {
    width: 0px;
    height: 600px;
    border-left: 1px solid #dddddd;
  }
}
a.permission_edit {
  cursor: not-allowed;
}
.ant-input {
  width: 150%;
}
.refresh {
  padding: 13px 0 30px 0;
}
.handle_logout {
  position: relative;
  left: 88%;
}
.error {
  color: red;
  font-size: 12px;
  position: relative;
  top: -26px;
  left: 70px;
}
</style>

<style>
.jurisdictionView-checkbox-group label {
  padding: 15px !important;
  border-bottom: 1px solid #eeeeee;
}
</style>
